/* eslint-disable jsx-a11y/img-redundant-alt */

//importing images
import { Link } from "react-router-dom";

//importing styles
import "../../../../assets/scss/components/card/featuredProfileCard/featuredProfileCard.scss";
import SkeletonBox from "../../../Skeleton/SkeletonBox";
import Skeleton from "react-loading-skeleton";

const FeaturedProfileCard = ({ cardDetail }) => {
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };
  return (
    <div className="card-main-container m-2 coach-card-width">
      <div className="card  p-2">
        {cardDetail?.avatar  ?
        <div className="img-size">
          <img className="card-img-top " src={cardDetail?.avatar} alt="Card image cap" />
        </div>:
          <Skeleton height={150}/>
        }
        <div className="card-body max-card-h p-2 d-flex justify-content-between">
          {cardDetail?
          <>
            <Link className="text-decoration-none" to={`/coach/${cardDetail?.slug}`} >
              <p className="card-text m-0 text-dark fw-bold m-1 w-100">
                {cardDetail?.full_name || <Skeleton width={100}/>}
              </p>
            </Link>
            {/* <div className="card-text m-0 text-secondary m-1  text-end overflow-hidden">
              {stripHtmlTags(cardDetail?.expertise)}
            </div> */}
          </>:
            <SkeletonBox>
              <Skeleton/>
            </SkeletonBox>
          }
          
        </div>
      </div>
    </div>
  );
};

export default FeaturedProfileCard;
