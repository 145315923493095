//importing images
import Banner from "../../assets/images/FeaturedWhyUsSection/BannerFeatured.webp";
import Icon1 from "../../assets/images/FeaturedWhyUsSection/Icon1.webp";
import Icon2 from "../../assets/images/FeaturedWhyUsSection/Featuredicon2.webp";
import Icon3 from "../../assets/images/FeaturedWhyUsSection/Featuredicon3.webp";
import Icon4 from "../../assets/images/FeaturedWhyUsSection/Featuredicon4.webp";
import Icon5 from "../../assets/images/FeaturedWhyUsSection/Featuredicon5.webp";

//importing styles
import "../../assets/scss/components/featuredWhyUsSection/featuredWhyUsSection.scss";

const FeaturedWhyUsSection = () => {
  return (
    <div className="featured-whyus-main-conatiner">
      <div className="featured-whyus-inner-conatiner row w-100 gap-5">
        <div className="col p-0">
          <img src={Banner} alt="sampleBanner" className="w-100" />
        </div>
        <div className="col">
          <h1 className="fw-bolder mb-5">Why Learn in ReSkills</h1>
          <div className="row gap-2">
            <div className="d-flex flex-column col-4">
              <img src={Icon1} alt="icon" className="w-50 mb-2" />
              <span className="fw-bold">Fast Convenient</span>
            </div>
            <div className="d-flex flex-column col-4">
              <img src={Icon2} alt="icon" className="w-50 mb-2" />
              <span className="fw-bold">Anytime Anywhere</span>
            </div>
            <div className="w-100"></div>
            <div className="d-flex flex-column col-4">
              <img src={Icon3} alt="icon" className="w-50 mb-2" />
              <span className="fw-bold">Affordable</span>
            </div>
            <div className="d-flex flex-column col-4">
              <img src={Icon4} alt="icon" className="w-50 mb-2" />
              <span className="fw-bold">Variety</span>
            </div>
            <div className="w-100"></div>

            <div className="d-flex flex-column col-4">
              <img src={Icon5} alt="icon" className="w-50 mb-2" />
              <span className="fw-bold">Global Recognition</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedWhyUsSection;
