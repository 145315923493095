import { useNavigate } from "react-router-dom";

//importing styles
import "../../../assets/scss/pages/auth/successPages/changePasswordSuccess.scss";

//importing components
import Button from "../../../components/common/button/Button";

//importing icons
import successIcon from "../../../assets/images/Auth/SuccessIcon.webp";

const ChangePasswordSuccess = () => {
    const navigate = useNavigate();
    return (
        <div className="changePasswordSuccess-container w-25 mx-auto mt-5">
            <div className="text-center">
                <img src={successIcon} alt="siteLogo" className="mb-4" />
                <h2 className="fw-bold fs-3 mb-2">Password Reset</h2>
                <p className="mb-4 text-secondary text center fs-6">
                    Your password has been successfully reset.<br /> Click below to log in
                    ReSkills.
                </p>
            </div>
            <Button name="Continue" onClick={() => navigate("/login")} />
        </div>
    );
};

export default ChangePasswordSuccess;
