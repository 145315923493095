/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { useState } from "react";

//import react rating
import { Rating } from "react-simple-star-rating";

//importing images
import ratingIcon from "../../../../assets/images/Rating/ratingIcon.webp";
import dateIcon from "../../../../assets/images/Cards/HorizentalCard/dateIcon.webp";
import timerIcon from "../../../../assets/images/Cards/HorizentalCard/timerIcon.webp";
import wwwIcon from "../../../../assets/images/Navbar/wwwIcon.webp";
// import avatar from "../../../../assets/images/Cards/HorizentalCard/Avatar.webp";
// import avatar from "../../../../assets/images/Cards/HorizentalCard/Avatar.webp";
import avatar from "../../../../assets/images/Auth/avatar2.webp";




//import button component
import Button from "../../button/Button";
import SkeletonBox from "../../../Skeleton/SkeletonBox";
import Image from "../../image";
//import styles
import "../../../../assets/scss/components/horizentalCard/horizentalCard.scss";
import { selectLoggedIn } from "../../../../redux/userSlice";
import Skeleton from "react-loading-skeleton";

const HorizentalCard = ({ cardContent }) => {
    const navigate = useNavigate();
    const login = useSelector(selectLoggedIn);



    const date = new Date();
    const currenttTime = moment
        .parseZone(date)
        .local()
        .format()
        .replace(/-/g, "");

    const classEndDate = moment
        .parseZone(cardContent?.end_date_time)
        .local()
        .format()
        .replace(/-/g, "");

    const parseHTMLString = (htmlString) => {
        // Create a DOMParser instance
        const parser = new DOMParser();

        // Parse the input string as HTML
        const doc = parser.parseFromString(htmlString, 'text/html');

        // Check if there are any <p> tags in the parsed HTML
        const paragraphs = doc.querySelectorAll('p');

        // If there are <p> tags, extract their text content
        if (paragraphs.length > 0) {
            return Array.from(paragraphs).map(p => p.textContent);
        }

        // If there are no <p> tags, return the input string as a single-element array
        return [htmlString];
    };

    
    return (
        <div className="horizental-card-container">
            <div className="card shadow-sm border-0  card-high">
                <div className="row g-0">
                    <div className="col-xl-5">

                        {cardContent ?
                            <Link to={`/class/${cardContent?.slug}`} className="text-dark   text-decoration-none">
                                <img
                                    src={cardContent?.images
                                        ? cardContent?.images[0]?.image
                                        : cardContent?.thumb_image}
                                    className="card-img img-fluid rounded-start w-100 cursor-pointer"
                                    alt="..."
                                />
                            </Link>
                            :
                            <SkeletonBox>
                                <Skeleton height={275} />
                            </SkeletonBox>

                        }
                    </div>
                    <div className="col-xl-7">
                        <div className="card-body px-4 py-3">
                            {cardContent?.class_type?.name ?
                                <p className="bg-primary-btn text-white text-center  rounded live-btn px-3 fw-semibold ">
                                    {cardContent?.class_type?.name}
                                </p> :
                                <Skeleton width={100} height={20} />
                            }

                            <Link to={`/class/${cardContent?.slug}`} className="text-dark   text-decoration-none">
                                <h5 className="card-title fw-bold text-truncate mb-3">{cardContent?.title || <Skeleton height={25} />}</h5>
                            </Link>
                            <p className="card-text mb-4">
                                <small className="text-muted">{parseHTMLString(cardContent?.short_description) || <Skeleton count={2} />}</small>
                            </p>
                            <div className="card-details-container d-flex justify-content-between">
                                {cardContent?.start_date_time ?
                                    <div className="d-flex align-items-centre gap-1">
                                        <img src={dateIcon} alt="dateIcon" style={{width:'20px' , height: '20px' ,marginTop:'2px'}}/>
                                        {cardContent?.start_date_time &&
                                            moment(cardContent?.start_date_time).format(
                                                "ddd, Do MMM YYYY"
                                            )}
                                    </div> :
                                    <SkeletonBox>
                                        <Skeleton width={100} />
                                    </SkeletonBox>
                                }
                                <div className="d-flex align-items-centre gap-1">
                                    {cardContent?.start_date_time ?
                                        <>
                                            <img src={timerIcon} alt="dateIcon" style={{width:'20px' , height: '20px' ,marginTop:'2px'}}/>
                                            {cardContent?.start_date_time &&

                                                moment
                                                    .parseZone(cardContent?.start_date_time)
                                                    .local()
                                                    .format("hh:mm A")}{" "}
                                        </> :
                                        <SkeletonBox>
                                            <Skeleton width={100} />
                                        </SkeletonBox>
                                    }

                                </div>
                                <div className="d-flex align-items-centre gap-1">
                                    {cardContent?.language?.name ?
                                        <>
                                            <img src={wwwIcon} alt="dateIcon"  style={{width:'20px' , height: '20px' ,marginTop:'2px'}}/>
                                            {cardContent?.language?.name}
                                        </> :
                                        <SkeletonBox>
                                            <Skeleton width={100} />
                                        </SkeletonBox>

                                    }
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-3 ">
                                {cardContent?.coaches?.map((item, index) => {
                                    return (
                                        <div className="d-flex gap-2 align-items-center" key={`coach-${item?.id}`}>

                                            <div className="rounded-circle overflow-hidden">
                                                <Image
                                                    src={item?.avatar}
                                                    alt="profile"
                                                    style={{ width: "45px", height: "45px" }}
                                                    defaultImage={avatar}
                                                />

                                            </div>
                                            <div>
                                                <Link className="text-dark text-decoration-none"  to={`/coach/${item?.slug}`}>
                                                    <p className="mb-0 fw-bold">{item?.full_name || <Skeleton />}</p>
                                                </Link>
                                                {cardContent?.average_rating !== undefined ?
                                                    <>
                                                        <Rating
                                                            initialValue={cardContent?.average_rating}
                                                            value={cardContent?.average_rating}
                                                            customIcons={ratingIcon}
                                                            readonly={true}
                                                            size={20}
                                                            allowFraction={true}
                                                        />{" "}

                                                    </> :
                                                    <Skeleton />
                                                }

                                            </div>
                                        </div>
                                    );
                                })}


                                {currenttTime < classEndDate && cardContent?.slug &&
                                    <div
                                        onClick={() =>
                                            login
                                                ? navigate(`/class/${cardContent?.slug}`)
                                                : navigate("/login")
                                        }
                                        className="mx-2-small"
                                    >
                                        <Button name={cardContent?.registrations ?cardContent?.registrations[0]?.registration_date  ? "Registered ":"Register Now" :"Register Now"}  btnType={cardContent?.registrations ?cardContent?.registrations[0]?.registration_date  ? "classEnded":"" :""}/>
                                    </div>
                                }
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default HorizentalCard;
