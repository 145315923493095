import React from 'react';
import Rating from 'react-rating';
import Image from '../common/image';
import BgImg from "../../assets/images/CoachReview/bg.webp";
import AvatarImg from "../../assets/images/CoachReview/Avatar.webp";
import ratingIcon from "../../assets/images/Rating/ratingIcon.webp";



function ViewAllReview(props) {

    return (
        <div>
            <div className="d-flex justify-content-center my-2 ">
                        <div class="card coach-review-width">
                            <Image 
                                src={BgImg } 
                                alt="bg" 
                                className="coach-bgImg" 
                                defaultImage={BgImg}
                            />
                            <div className='main-icon-container'>
                                <img src={AvatarImg} alt='' className='coach-icon' />
                                {/* <div className='small-icon-container d-flex mt-2'>
                            <div className='small-icons mx-2'></div>
                            <div className='small-icons mx-2'></div>
                            <div className='small-icons mx-2'></div>
                        </div> */}
                            </div>

                            <div class="card-body coach-profile ">
                                <h3 className="fw-semibold">{'Rishi'}</h3>
                                <div className="d-flex">
                                    <Rating
                                        initialValue={5}
                                        value={4}
                                        customIcons={ratingIcon}
                                        readonly={true}
                                        size={20}
                                        allowFraction={true}
                                    />
                                    <div className="p-1 text-muted">{(123 * 10) / 10}/5</div>
                                </div>
                                <div className="mt-3">
                                    <div>
                                        {'coachDetail?.coach?.description'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
    );
}

export default ViewAllReview;