import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

//importing images
import plus from "../../assets/images/Profile/plus.webp";
import check from "../../assets/images/Profile/check.webp";
import searchIcon from "../../assets/images/Navbar/search-Icon.webp";
import cross from "../../assets/images/Profile/cross.webp";

//importing components
import Button from "../common/button/Button";

//importing styles
import "../../assets/scss/components/modal/editModalPreference.scss";

//importing animation
import { selectCategoryList } from "../../redux/categorySlice";
import { updateUserProfile } from "../../services/user.service";
import { toast } from "react-toastify";
import {
  searchCategory,
} from "../../services/category.service";
import { setUserDetail, selectUserDetails } from "../../redux/userSlice";

const EditPreferenceTopic = ({ isShow, setIsShow, selectedItem, setSelectedItem }) => {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUserDetails);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState();
  const [searching, setSearching] = useState(false);
  const category = useSelector(selectCategoryList);

  const handleClose = () => {
    setIsShow(false);
    if (userDetail?.categories?.length > 0) {
      setSelectedItem(userDetail?.categories?.map(item => item?.id))
    } else {
      setSelectedItem([])
    }
  };

  const handleClick = (id) => {
    if (!selectedItem?.includes(id)) {
      setSelectedItem([...selectedItem, id]);
    } else {
      setSelectedItem(selectedItem.filter(item => item !== id));
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const result = await updateUserProfile({
      category_ids: selectedItem || [],
    });
    if (result?.data?.status === "success") {
      dispatch(setUserDetail(result?.data?.user));
      toast.success("Your preferences updated successfully");
      handleClose()
    } else {
      toast.error(result?.response?.data?.error || "Something went wrong");
    }
    setIsLoading(false);
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const handleSearch = async (event) => {
    setSearching(true);
    const { value } = event?.target;
    const result = await searchCategory({
      searchQuery: value,
    });
    setSearchResult(result?.data?.categories);
    setSearching(false);
  };
  // eslint-disable-next-line
  const optimisedVersion = useCallback(debounce(handleSearch), []);

  return (
    <div className="editPreference-main-container">
      <Modal show={isShow} onHide={handleClose}>
        <img
          src={cross}
          alt="icon"
          className="position-absolute top-0 end-0 cursor-pointer"
          onClick={() => handleClose()}
        />
        <div className="p-4">
          <h4 className="fw-bold">Preference Topics</h4>
          <div className="position-relative">
            <img
              src={searchIcon}
              alt="search-icon"
              className="search-icon position-absolute"
              style={{ top: "8px", left: "16px" }}
            />
            <input
              className={`${searchQuery?.length > 0 ? "bg-white" : "bg-transparent"
                } form-control border px-5 mt-4 mb-2`}
              type="search"
              id="input-field"
              placeholder="Search"
              aria-label="Search"
              onChange={optimisedVersion}
            />
          </div>
          <div>
            {searching ? (
              <p className="text-center mt-5">Loading...</p>
            ) : (
              (searchResult || category)?.map((item, index) => {
                return (
                  <div
                    className={`${selectedItem?.includes(item?.id) && ""
                      } d-flex align-items-center justify-content-between cursor-pointer  rounded p-2`}
                    onClick={() => {
                      setSelected(item?.id);
                      handleClick(item?.id);
                    }}
                    key={`category-${index}`}
                  >
                    <p
                      className={`${selectedItem?.includes(item?.id) && "text-primary"
                        }`}
                    >
                      {item?.name}
                    </p>
                    {selectedItem?.includes(item?.id) ? (
                      <img src={check} alt="icon" />
                    ) : (
                      <img src={plus} alt="icon" />
                    )}
                  </div>
                );
              })
            )}
          </div>
          <div className="d-flex gap-2 justify-content-end align-items-center">
            <div style={{ width: "140px" }}>
              <Button
                btnType="whiteButton"
                name="Cancel"
                onClick={() => handleClose()}
              />
            </div>
            <div style={{ width: "140px" }}>
              <Button
                name={isLoading ? "Loading..." : "Save"}
                onClick={() => handleSave()}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditPreferenceTopic;
